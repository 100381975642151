
    var dependentToggleGroups = {};
    var checkListeners = [];

    function setupModalToggle (toggle) {

        var toggleTargetSelector = toggle.dataset.lkwlatToggleTarget;

        toggle.addEventListener('click', function (eventArgs) {
            eventArgs.preventDefault();

            var toggleTargets = document.querySelectorAll(toggleTargetSelector);

            for (var i = 0; i < toggleTargets.length; i++) {
                toggleTargets[i].classList.toggle('lkwlat-modal--visible');
                toggleTargets[i].classList.add('lkwlat-modal--activated');
            }

        });

    }

    function updateDependentToggles (toggle, toggleGroup) {

        var behaviorAnd = true;

        if (toggle.behavior === 'and') {

            behaviorAnd = toggle.element.checked;

        } else {

            for (var i = 0; i < toggleGroup.length; i++) {
                if (!toggleGroup[i].behavior) {
                    behaviorAnd = behaviorAnd && toggleGroup[i].element.checked;
                }
            }

        }

        for (var i = 0; i < toggleGroup.length; i++) {
            if(toggleGroup[i].behavior == 'and' || toggle.behavior === 'and') {

                var changeEvent = document.createEvent("HTMLEvents");
                changeEvent.initEvent("change", false, true);

                toggleGroup[i].element.checked = behaviorAnd;
                toggleGroup[i].element.dispatchEvent(changeEvent);

            }
        }

    }

    function setupDependentToggle (toggleElement) {

        var toggleGroupName = toggleElement.dataset.lkwlatToggleGroup;
        var toggleBehavior = toggleElement.dataset.lkwlatToggleBehavior;

        var toggleGroup = dependentToggleGroups[toggleGroupName] = dependentToggleGroups[toggleGroupName] || [];
        var toggle = {
            element: toggleElement,
            behavior: toggleBehavior
        };

        toggleGroup.push(toggle);

        updateDependentToggles(toggle, toggleGroup);

        toggleElement.addEventListener('click', function (eventArgs) {

            updateDependentToggles(toggle, toggleGroup);

        });

    }

    function setupToggle (toggle) {

        var toggleType = toggle.dataset.lkwlatToggle;

        switch (toggleType) {
            case 'modal':
                setupModalToggle(toggle);
                break;
            case 'dependent':
                setupDependentToggle(toggle);
                break;
        }

    }

    function updateCheckObserverTargets (observer, targetSelector, className) {

        var targets = document.querySelectorAll(targetSelector);
        var classNames = className.split(' ');

        for (var i = 0; i < targets.length; i++) {

            if (observer.checked) {

                for (var j = 0; j < classNames.length; j++) {
                    targets[i].classList.add(classNames[j]);
                }

            } else {

                for (var j = 0; j < classNames.length; j++) {
                    targets[i].classList.remove(classNames[j]);
                }

            }

        }

    }

    function setupCheckObserver (observer) {

        var targetSelector = observer.dataset.lkwlatObserverTarget;
        var className = observer.dataset.lkwlatObserverClass;

        updateCheckObserverTargets(observer, targetSelector, className);

        checkListeners.push(function () {

            updateCheckObserverTargets(observer, targetSelector, className);

        });

    }

    function setupObserver (observer) {

        var observerType = observer.dataset.lkwlatObserver;

        switch (observerType) {
            case 'check':
                setupCheckObserver(observer);
                break;
            default:

        }

    }

    function setupModal (modal) {

        modal.addEventListener('click', function (eventArgs) {

            if (eventArgs.target === modal) {

                modal.classList.remove('lkwlat-modal--visible');

            }

        });

    }

    function setupCheckListeners () {

        var allCheckElements = document.querySelectorAll('input[type="radio"], input[type="checkbox"]');

        var onCheckChanged = function () {
            for (var i = 0; i < checkListeners.length; i++) {
                checkListeners[i]();
            }
        };

        for (var i = 0; i < allCheckElements.length; i++) {
            allCheckElements[i].addEventListener('change', onCheckChanged);
        }

    }

    function setupClear (clear) {
        var target = clear.dataset.lkwlatClearTarget;
        var enable = clear.dataset.lkwlatEnableTarget;

        clear.addEventListener('change', function (eventArgs) {
            var disableElement = document.getElementById(target);
            var enableElement = document.getElementById(enable);
            if (clear.checked && disableElement && enableElement) {
                clearValues[target] = disableElement.value;
                disableElement.value = "";
                disableElement.disabled = true;
                disableElement.required= false;

                enableElement.disabled = false;
                enableElement.required = true;
                if (clearValues[enable]) {
                    enableElement.value = clearValues[enable];
                }
            }
        });
    }


    document.addEventListener("turbolinks:load", function () {
        var i = 0;
        dependentToggleGroups = {};
        checkListeners = [];
        clearValues = [];

        var toggles = document.querySelectorAll('[data-lkwlat-toggle]');

        for (i = 0; i < toggles.length; i++) {

            setupToggle(toggles[i]);

        }

        var observers = document.querySelectorAll('[data-lkwlat-observer]');

        for (i = 0; i < observers.length; i++) {

            setupObserver(observers[i]);

        }

        var modals = document.querySelectorAll('.lkwlat-modal');

        for (i = 0; i < modals.length; i++) {

            setupModal(modals[i]);

        }

        setupCheckListeners();

        var clearer = document.querySelectorAll('[data-lkwlat-clear-target]');

        for (i = 0; i < clearer.length; i++) {

            setupClear(clearer[i]);

        }
    });
