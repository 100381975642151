import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from 'activestorage';
import 'bootstrap';
import Trix from 'trix';
import './page.js';
import * as klaro from 'klaro';

Trix.config.toolbar.getDefaultHTML = function() {
    return "";
};
Trix.config.blockAttributes.heading = { tagName: 'h2' };

Rails.start();
Turbolinks.start();
ActiveStorage.start();
window.jQuery = $;

window.setupSort = function() {
    $(".js-sort-option").on('click', function(e){
        $("#sortDropdownBtn").text($(this).text()).val($(this).text());
        $('#sort_option').val($(this).data('sort'));
        Rails.fire($('#search-form')[0], 'submit');
        e.preventDefault();
    });
};

var klaroConfig = {
    version: 1,
    styling: {
        theme: ['dark', 'bottom', 'wide'],
    },
    cookieName: 'lkw-klaro',
    cookieExpiresAfterDays: 365,
    groupByPurpose: false,
    acceptAll: true,
    translations: {
        de: {
            privacyPolicyUrl: '/datenschutzerklaerung',
            consentNotice: {
                description: 'Wollen Sie der Setzung aller Cookies zustimmen, dann klicken Sie bitte auf den "Ich stimme zu" Button. Wir weisen darauf hin, dass hiermit auch der Setzung von Cookies US-amerikanischer Anbieter zugestimmt wird, wodurch Ihre durch das entsprechende Cookie erhobenen personenbezogenen Daten keinem dem EU-Datenschutz angemessen Schutzniveau unterliegen, Sie nur eingeschränkte bis keine datenschutzrechtliche Rechte in den USA genießen und insbesondere auch die US-amerikanische Regierung Zugang zu diesen Daten erlangen kann.\n' +
                    'Wollen Sie keine oder nur der Setzung einzelner Cookies zustimmen, dann klicken Sie bitte auf "Cookie-Einstellungen", um Ihre Cookies entsprechend zu verwalten.',
                learnMore: "Cookie-Einstellungen",
            },
            consentModal: {
                description: 'Wollen Sie der Setzung aller Cookies zustimmen, dann klicken Sie bitte auf den "Ausgewählte akzeptieren" Button. Wir weisen darauf hin, dass hiermit auch der Setzung von Cookies US-amerikanischer Anbieter zugestimmt wird, wodurch Ihre durch das entsprechende Cookie erhobenen personenbezogenen Daten keinem dem EU-Datenschutz angemessen Schutzniveau unterliegen, Sie nur eingeschränkte bis keine datenschutzrechtliche Rechte in den USA genießen und insbesondere auch die US-amerikanische Regierung Zugang zu diesen Daten erlangen kann.\n' +
                    'Die Ausspielung dieser zustimmungspflichtigen Cookies erfolgt durch den Google Tagmanger, der US-amerikanischen Firma Google. Wenn Sie daher einem der unten aufgelisteten Cookies zustimmen, so stimmen Sie auch zu, dass die Ausspielung über den Google Tag Manager erfolgt. Wenn Sie keine Cookies oder den Google Tag Manager wünschen, dann klicken Sie bitte gleich auf den grünen „Ausgewählte akzeptieren“ Button.\n' +
                    'Wollen Sie keine oder nur der Setzung einzelner Cookies zustimmen, dann klicken Sie bitte auf die jeweiligen Optionen, um Ihre Cookies entsprechend zu verwalten.',
            },
            decline: "Ich lehne ab",
            ok: "Ich stimme zu",
            typekit: {
                description: 'Schriftart für die Homepage. Hinweis: Eine Deaktivierung beeinflusst das Erscheinungsbild der Website.',
                title: 'Adobe Typekit',
            },
            google: {
                description: 'Webseitentracking und Optimierung mit Google Analytics.',
                title: 'Google Analytics (US-amerikanischer Anbieter)'
            },
            facebook: {
                description: 'Ermöglicht die Auslieferung zielgerichteter Werbung auf Facebook.',
                title: 'Facebook (US-amerikanischer Anbieter)',
            },
            youtube: {
                description: 'Videos von YouTube vollständig anzeigen.',
                title: 'YouTube',
            },
            purposes: {
                analytics: {
                    title: 'Marketing'
                },
                styling: {
                    title: 'Styling'
                },
                content: {
                    title: 'Social Media / externe Inhalte'
                },
            },
        },
    },

    services: [
        {
            name: 'typekit',
            purposes: ['styling'],
        },
        {
            name: 'youtube',
            purposes: ['content'],
        },
        {
            name: 'google',
            purposes: ['analytics'],
        },
        {
            name: 'facebook',
            purposes: ['analytics'],
        }
    ],
};


function documentReady() {
    $('.js-toggle-offer').on('change', function() {
        var data = "published=" + (this.checked ? 1 : 0);
        var $row = $(this).closest('.row');
        $row.removeClass('finished');
        Rails.ajax({
            url: $(this).data('offer-url'),
            type: "PUT",
            data: data,
            success: function() {
                $row.addClass('finished');
            },
            error: function() {
                alert('Status konnte nicht geändert werden');
            }
        });
    });

    $('.js-insert-text').on('click', function(e) {
        var element = document.querySelector("trix-editor");
        element.editor.setSelectedRange([0, 0]);
        element.editor.insertHTML($('.js-template-text').html());
        $('#offer_caption').val($('.js-template-header').text());
        e.preventDefault();
    });

    var $matchHeight = $('.js-match-height-container');
    if ($matchHeight.length && !$('#mobile-check').is(":visible")) {
        $matchHeight.css('min-height', ($('#grow-content').height()-$('nav').height()) + "px");
    }

    $(document).on('keyup', '.lkw-search-field-zip', function(e) {
        var $target = $(e.target);
        if ($target.val().length < 4) {
            return;
        }
        Rails.fire($('#search-form')[0], 'submit');
    });

    $('#search-form').on('change', function(e) {
        var $target = $(e.target);
        if ($target.hasClass('lkw-search-field-name') ||
            $target.hasClass('lkw-search-field-zip')  ||
            ($target.hasClass('lkw-search-field-range') && $('.lkw-search-field-zip').val().length < 4)) {
            return;
        }

        Rails.fire($('#search-form')[0], 'submit');
    });

    var $editForm = $('#edit_logo');
    var $employerLogo = $('#employer_logo');
    if ($editForm.length) {
        $employerLogo.on('ch').on('change', function() {
            $editForm.submit();
        });
    }
    else {
        $employerLogo.change(function() {
            if (this.files && this.files[0]) {
                var reader = new FileReader();

                reader.onload = function(e) {
                    let $offerPreviewPicture = $('#employer_logo_preview');
                    $offerPreviewPicture.attr('src', e.target.result);
                    $offerPreviewPicture.show();
                };

                reader.readAsDataURL(this.files[0]);
            }
        });
    }

    $("#offer_picture").change(function() {
        if (this.files && this.files[0]) {
            var reader = new FileReader();

            reader.onload = function(e) {
                let $offerPreviewPicture = $('#offer_preview_picture');
                $offerPreviewPicture.attr('src', e.target.result);
                $offerPreviewPicture.show();
            };

            reader.readAsDataURL(this.files[0]);
        }
    });

    $('.js-reply-file').on('change', function(event) {
        var $list = $('#selected_files');
        $list.empty();
        $('.js-reply-file').each(function(index, element) {
            for (var i = 0; i < element.files.length; ++i) {
                $list.append('<li>' + this.files.item(i).name + '</li>');
            }
        });

    });

    $('.js-clear-reply-files').on('click', function(e) {
        e.preventDefault();
        $('.js-reply-file').val('');
        $('#selected_files').empty();
    });

    window.setupSort();

    var form = document.getElementById('register-employer');
    if (form) {
        form.onsubmit = function() {
            let $employerPassword = $('#employer_password');
            let $employerPasswordConfirmation = $('#employer_password_confirmation');
            if ($employerPassword.val() !== $employerPasswordConfirmation.val()) {
                $employerPassword.css('color', 'red');
                $employerPasswordConfirmation.css('color', 'red');
                return false;
            }
            return true;
        };
    }

    let offerForm = document.getElementById('offer_form');
    if (offerForm) {
        offerForm.onsubmit = function() {
            let $content = $('#offer_content');
            let result = true;
            if ($content.val() === '') {
                $('.trix-content').css('border-color', 'red');
                result = false;
            }

            $(offerForm).find('.js-require-checked').each(function(index, element) {
                if ($(element).find(':checkbox:checked').length === 0) {
                    $(element).css('border', '1px solid red');
                    result = false;
                }
            });
            return result;
        };
    }

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };
    if (getUrlParameter('sent') === '1') {
        if (window.dataLayer && typeof window.dataLayer !== 'undefined' ) {
            window.dataLayer.push({'event': 'formSubmissionSuccess', 'formId': 'bewerbung'});
        }
    }

    $('[data-sound]').on('click', function(e) {
        e.preventDefault();
        var $player = $(this).find('audio');

        if ($player.length > 0) {
            var $status = $player[0].paused;
        }

        $('audio').each(function(){
            this.pause();
        });

        if ($player.length === 0) {
            $(this).append( "<audio src=\"" + $(this).data('sound') + "\" autoplay />" );
        }
        else {
            if ($status) {
                $player.trigger('play');
            }
            else {
                $player.trigger('pause');
            }
        }
    });

    window.klaro = klaro;
    window.klaroConfig = klaroConfig;
    klaro.setup(klaroConfig);
    klaro.getManager(klaroConfig).applyConsents();
}

document.addEventListener('turbolinks:load',  function(event) {
    if (window.dataLayer && typeof window.dataLayer !== 'undefined' ) {
        window.dataLayer.push({
            'event':'pageView',
            'virtualUrl': event.data.url
        });
    }
    if (typeof window.fbq === "function") {
        window.fbq('track', 'PageView');
    }
    documentReady();
});

document.addEventListener('turbolinks:before-cache',  function(event) {
    $('audio').remove();
});
